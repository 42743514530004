.contactInfo {
	position: fixed;
	width: 100%;
	height: 100%;
}

.backGround {
	position: absolute;
	width: 100%;
	height: 100%;
    background-color: black;
}

.topLeft {
	position: fixed;
	width: 50%;
	height: 50%;
}

.topRight {
    position: fixed;
    width: 50%;
    height: 50%;
	left: 50%;
}

.botLeft {
    position: fixed;
    width: 50%;
    height: 50%;
	top:50%
}

.botRight {
    position: fixed;
    width: 50%;
    height: 50%;
	top:50%;
	left:50%;
}

.pContactsText {
	margin: auto;
    font-size: 4vw;
    color: white;
    font-family: 'Rudiment';
    text-align: center;
	position: absolute;
	top: 50%;
	left:50%;
	transform: TranslateY(-50%) TranslateX(-50%);
}
