.cv {
	position: fixed;
	width: 100%;
	height: 100%;
}

.superiorEducationDiv {
	position: absolute;
	width: 50%;
	height: 50%;
	top: 25%;
	font-size: 4vw;
	color: white;
	font-family: 'Rudiment';
	text-align: center;
}

.workExperienceTitle {
	position: absolute;
	width: 50%;
	top: 50%;
	font-size: 4vw;
	color: white;
	font-family: 'Rudiment';
	text-align: center;
	transform: translateY(-50%);
}

.workExperienceBody {
    position: absolute;
	width: 50%;
	font-size: 2vw;
    color: white;
    font-family: 'Rudiment';
    text-align: center;
}

.skillsAndCertsTitle {
    position: absolute;
    width: 50%;
    top: 50%;
    font-size: 4vw;
    color: white;
    font-family: 'Rudiment';
    text-align: center;
    transform: translateY(-50%);
}

.skillsAndCertsBody {
    position: absolute;
    width: 50%;
	left: 50%;
    font-size: 2vw;
    color: white;
    font-family: 'Rudiment';
    text-align: center;
}

.currentProjects {
    position: absolute;
    width: 70%;
    height: 70%;
    font-size: 3vw;
    color: white;
    font-family: 'Rudiment';
    text-align: center;
}

