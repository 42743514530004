.intro {
	position: fixed;
	width: 100%;
	height: 100%;
}

.introPhoto {
	position: absolute;
	left: 12.5%;
	width: 25%;
}

.paralaxNotice {
	font-size: 4vw;
	color: white;
	text-align: center;
	font-family: 'Rudiment';
}

.introText {
	position: absolute;
	width: 50%;
	height: 50%;
	top: 25%;
	left: 40%;
    font-size: 4vw;
    color: white;
    font-family: 'Rudiment';
	display: inline-block;
}


