.orientationAlert {
	position: fixed;
	width: 100%;
	height: 100%;
}

.splashImage {
	width: 75%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40%;
}

.alertText{
	color:white;
	width: 75%;
	display:block;
	margin-left: auto;
	margin-right: auto;
	font-size: 200%;
	text-align: center;
	font-family: 'Rudiment';
}
